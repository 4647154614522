<template>
  <div id="add-credit-view">
    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'DynamicProducts'">
    </breadcrumb-base>

    <div class="">

      <div class="vx-row w-full my-5 mx-3" v-if="Object.keys(customer).length > 0">
        <div class="vx-col">
          <h3><strong>{{$t('CustomerName')}}</strong></h3>
          <h4>{{customer.first_name || ''}} {{customer.last_name || ''}}</h4>
        </div>
        <div class="vx-col">
          <h3><strong>{{$t('CustomerID')}}</strong></h3>
          <h4>{{customer.customer_id || ''}}</h4>
        </div>
      </div>
      <div class="vx-row w-full my-5 mx-3">
        <vx-card :title="$t('DynamicProductsDiscounts')">

          <div class="vx-row">
            <vs-spacer></vs-spacer>
            <div class="vx-col md:w-1/3 pt-6">

              <vs-input class="inputx pt-8 w-full"  type="number" :placeholder="$t('DirectRechargeDiscount')" :label="$t('DirectRechargeDiscount')" v-model="drDiscount"/>

              <vs-input class="inputx pt-8 w-full" type="number"  :placeholder="$t('InternationalTopUpDiscount')"  :label="$t('InternationalTopUpDiscount')" v-model="intlTopUpDiscount"/>

              <vs-input class="inputx pt-8 w-full" type="number"  :placeholder="$t('FlixBusDiscount')" :label="$t('FlixBusDiscount')" v-model="flixBusDiscount"/>

              <vs-button class="mt-12 flex" style="margin: auto" type="filled"  @click="saveDiscounts">{{$t('Save')}}</vs-button>
            </div>
            <vs-spacer></vs-spacer>
          </div>
        </vx-card>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'AddDiscount',
  data() {
    return {
      customer: {},
      isMounted: false,
      drDiscount: '',
      intlTopUpDiscount: '',
      flixBusDiscount: '',
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'Customers', i18n: 'Customers', url: '/customers/view' },
        { title: 'Dynamic Products', i18n: 'DynamicProducts', active: true },
      ],
    }
  },
  computed: {
  },
  props: {
    customerId: {
      type: String | Number,
    },
  },
  methods: {
    saveDiscounts() {
      let dr = 0
      let ir = 0
      let bus = 0
      if (this.drDiscount) dr = this.drDiscount
      if (this.intlTopUpDiscount) ir = this.intlTopUpDiscount
      if (this.flixBusDiscount) bus = this.flixBusDiscount
      const data = [
        {
          'customer_id': this.customerId, 'direct_recharge_discount': dr, 'dt_one_discount': ir, 'flixbus_discount': bus,
        },
      ]
      this.$vs.loading()
      return this.$store.dispatch('customer/updateDynamicDiscounts', JSON.stringify(data))
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          this.$router.push({ name: 'customer-details', params: { customerId: this.customerId } })
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },

    fetchCustomer() {
      this.$store.dispatch('customer/fetchCustomerDetails', this.customerId)
        .then((data) => {
          this.customer = data
        })
        .catch((error) => {
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
  created() {
    this.$vs.loading()
    return this.$store.dispatch('customer/fetchCustomerDetails', this.customerId)
      .then((data) => {
        this.$vs.loading.close()
        this.errorFetching = false
        this.drDiscount = data.direct_recharge_discount
        this.intlTopUpDiscount = data.dt_one_discount
        this.flixBusDiscount = data.flixbus_discount
      })
      .catch((error) => {
        console.error(error)
        this.$vs.loading.close()
        this.errorFetching = true
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: error.response.data.message || error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      })
  },
  mounted() {
    this.isMounted = true
    this.fetchCustomer()
  },
}
</script>

<style lang="scss" scoped>

</style>
